import { Link, useLocation } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import ChevronLeftIcon from '@/assets/icons/chevron-left.svg?react';
import BarsIcon from '@/assets/icons/bars.svg?react';
import XmarkIcon from '@/assets/icons/xmark.svg?react';
import ChartBarIcon from '@/assets/icons/chart-bar.svg?react';
import UsersIcon from '@/assets/icons/users.svg?react';

import styles from './navbar.module.scss';
import { useEffect, useState } from 'react';
import { classnames } from '@/utils/functions';
import ThreadsList from './ThreadsList';
import UserOptions from './UserOptions';
import useResponsive from '@/hooks/useResponsive';
import Button from '../Button';
import { Role } from '@/models/user';
import { hasThreadsAccess } from '@/utils/session';
import FeedbackCTA from '../FeedbackCTA';
import PricingTable from '../PricingTable';
import Modal from '../Modal/modal';
import { createPortal } from 'react-dom';

const NAVBAR_STORE_KEY = 'navbarCollapsed';

const Navbar = () => {
  const getCollapsedValueFromStore = () =>
    localStorage.getItem(NAVBAR_STORE_KEY) === 'true';

  const [collapsed, setCollapsed] = useState(getCollapsedValueFromStore());
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showRetentionModal, setShowRetentionModal] = useState(false);
  const { session, pushUserEvent } = useSession();

  const toggleMobileMenu = () => {
    setShowMobileMenu((current) => !current);
  };
  const toggleCollapse = () => {
    setCollapsed((current) => {
      const newValue = !current;
      localStorage.setItem(NAVBAR_STORE_KEY, newValue ? 'true' : 'false');

      pushUserEvent(`${newValue ? 'collapse' : 'expand'}_navbar`);
      return newValue;
    });
  };

  useResponsive(
    () => {
      setCollapsed(false);
      setShowMobileMenu(false);
    },
    () => {
      setCollapsed(getCollapsedValueFromStore());
      setShowMobileMenu(false);
    }
  );

  const location = useLocation();

  useEffect(() => {
    setShowMobileMenu(false);
  }, [location]);

  return (
    <nav
      className={classnames(
        styles['navbar'],
        { [styles['collapsed']]: collapsed },
        { [styles['show-mobile-content']]: showMobileMenu }
      )}
    >
      <button
        data-testid="navbar-trigger"
        className={styles['navbar-trigger']}
        onClick={toggleMobileMenu}
      >
        {showMobileMenu ? <XmarkIcon /> : <BarsIcon />}
      </button>
      <Link className={styles['logo-wrapper']} to="/">
        <img src="/gannet-white.png" height="60" alt="Gannet white logo" />
      </Link>
      <div className={styles['navbar-content']}>
        <div className={styles['navbar-menu']}>
          {hasThreadsAccess(session.roles) && (
            <Link data-testid={'new-thread-btn'} to="/">
              <Button
                className={styles['new-thread-btn']}
                onClick={() => {
                  pushUserEvent('click_new_thread');
                }}
              >
                {collapsed ? '+' : 'New Thread'}
              </Button>
            </Link>
          )}
          <Link to="/explore-data">
            <Button
              variation="secondary"
              className={styles['icon-btn']}
              onClick={() => {
                pushUserEvent('click_explore');
              }}
            >
              {collapsed ? <ChartBarIcon /> : 'Explore Data'}
            </Button>
          </Link>
          <FeedbackCTA text={collapsed ? '' : 'Share Feedback'} />
          {session.roles.includes(Role.ADMIN) && (
            <Link to="/users-management">
              <Button variation="secondary" className={styles['icon-btn']}>
                {collapsed ? <UsersIcon /> : 'Users Management'}
              </Button>
            </Link>
          )}
        </div>
        {!collapsed && hasThreadsAccess(session.roles) && (
          <>
            <span className={styles['threads-heading']}>
              All Threads ({session.threads.length + session.hiddenThreadsCount}
              )
              <Link
                to="search"
                onClick={() => {
                  pushUserEvent('click_search');
                }}
              >
                Search
              </Link>
            </span>
            {session.hiddenThreadsCount > 0 && (
              <div className={styles['threads-retention-wrapper']}>
                You have {session.hiddenThreadsCount} archived Thread
                {session.hiddenThreadsCount === 1 ? '' : 's'} due to the limit
                in your current plan.
                <button
                  onClick={() => {
                    setShowRetentionModal(true);
                  }}
                >
                  UPGRADE NOW
                </button>
              </div>
            )}

            <ThreadsList />
          </>
        )}
        <UserOptions collapsed={collapsed} />

        <button className={styles['collapse-trigger']} onClick={toggleCollapse}>
          <ChevronLeftIcon />
        </button>
      </div>

      {createPortal(
        <Modal
          title="Upgrade your Plan"
          isOpen={showRetentionModal}
          onClose={() => {
            setShowRetentionModal(false);
          }}
          size="large"
        >
          <p>
            To keep GANNET sustainable, some plans have a limit on the number of
            threads you can create. Currently, you have reached your limit, so
            older threads are hidden from view and will be deleted soon.
          </p>
          <p>
            Get back your{' '}
            <strong>
              {session.hiddenThreadsCount} thread
              {session.hiddenThreadsCount === 1 ? '' : 's'}
            </strong>{' '}
            before they are deleted by upgrading your plan:
          </p>

          <PricingTable heading={false} />
        </Modal>,
        document.body
      )}
    </nav>
  );
};

export default Navbar;
