import { AWS_USERS_API } from '@/config/aws';
import { get } from 'aws-amplify/api';
import { useQuery } from '@tanstack/react-query';
import { extractPayload } from '@/utils/api';
import { LexicalNode } from '@/models/lexical';

const useQueryCMS = (type: 'PRIVACY' | 'TERMS') => {
  const { data, isFetching } = useQuery({
    queryKey: ['cms', 'privacy'],
    queryFn: async () => {
      const { response } = get({
        apiName: AWS_USERS_API,
        path: '/policies',
        options: {
          headers: {
            'x-api-key': import.meta.env.VITE_CMS_API_KEY,
          },
        },
      });

      const { success, data } = await extractPayload<{
        success: boolean;
        data: Array<{
          type: string;
          updated_at: string;
          content: LexicalNode[];
        }>;
      }>(response);

      if (!success) {
        throw new Error('Failed to fetch CMS data');
      }

      const page = data.filter((item) => item.type === type)[0];

      return page;
    },
    initialData: {
      type: '',
      updated_at: '',
      content: [],
    },
  });

  return { data, isFetching };
};

export default useQueryCMS;
