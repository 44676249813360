import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useSession from '@/hooks/useSession';
import Form from '@/components/Form';
import PasswordInput from '@/components/PasswordInput';
import FormMessage from '@/components/FormMessage';
import Button from '@/components/Button';
import HomeLayout from '@/layouts/HomeLayout';
import SEOHelmet from '@/components/SEOHelmet';
import { signIn, signOut } from 'aws-amplify/auth';
import { createUserAuth } from '@/utils/session';
import { ErrorException } from '@/models/error';
import { gaIdentifyUser, gaPushEvent } from '@/utils/analytics';
import InputWrapper from '@/components/Form/InputWrapper';

const SignIn = () => {
  const { setSession } = useSession();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const userRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    if (userRef.current) userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd]);

  const handleSubmit = async (retry = 0) => {
    try {
      setLoading(true);
      await signIn({
        username: user,
        password: pwd,
        options: {
          authFlowType: 'USER_PASSWORD_AUTH',
        },
      });

      const newSession = await createUserAuth(user);
      setSession(newSession);
      gaIdentifyUser(newSession.sub);
      gaPushEvent('sign_in', user); // Use gaPushEvent instead of pushUserEvent since status updates are async.

      if (newSession.pendingPolicies.length > 0) {
        navigate('/policy-review');
      } else {
        navigate(from === '/' ? '/' : from, { replace: true });
      }
    } catch (err) {
      const error = err as ErrorException;

      if (error.name === 'UserAlreadyAuthenticatedException') {
        await signOut();
        gaPushEvent('auto_sign_out', user);
      }

      if (error.name === 'UserAlreadyAuthenticatedException' && retry < 1) {
        handleSubmit(retry + 1);
      } else {
        if (
          error.name === 'NotAuthorizedException' ||
          error.name === 'UserNotFoundException'
        ) {
          setErrMsg('The username or password is incorrect. Please try again.');
        } else {
          setErrMsg('An error ocurred.  Please try again later.');
          console.error(err);
        }
        setLoading(false);
      }
    }
  };

  return (
    <HomeLayout>
      <SEOHelmet title="Sign In" description="Sign in to GANNET" index={true} />
      <h1>Sign In</h1>
      <Form onSubmit={() => handleSubmit()}>
        <InputWrapper hideRequiredStyle={true}>
          <input
            type="text"
            id="username"
            name="username"
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setUser(e.target.value)}
            value={user}
            required
          />
          <label htmlFor="username">Email:</label>
        </InputWrapper>
        <PasswordInput
          hideRequiredStyle={true}
          password={pwd}
          setPassword={setPwd}
        />
        <Button variation="alt" loading={loading}>
          Sign In
        </Button>
      </Form>
      <p>
        <Link
          to={{
            pathname: '/forgot-password',
          }}
          state={{ email: user }}
        >
          Forgot your password?
        </Link>
      </p>
      <hr />
      <p>
        Need an Account?
        <br />
        <span>
          <Link to="/sign-up">Sign Up</Link>
        </span>
      </p>

      <FormMessage type="error" message={errMsg} />
    </HomeLayout>
  );
};

export default SignIn;
