import fetchThreads from '@/api/threads/fetchThreads';
import fetchPendingPolicies from '@/api/users/fetchPendingPolicies';
import fetchPromptUsage from '@/api/users/fetchPromptUsage';
import { DEFAULT_SESSION, Session } from '@/models/session';
import { Role } from '@/models/user';
import { fetchUserAttributes } from 'aws-amplify/auth';

export const getRoles = (username: string): Role[] => {
  let roles: Role[] = [];
  roles = [Role.USER];

  if (username.indexOf('+admin@datafriendlyspace.org') !== -1) {
    roles.push(Role.ADMIN);
  }

  return roles;
};

export const hasThreadsAccess = (roles: Role[]): boolean => {
  return !roles.includes(Role.ADMIN);
};

export const createUserAuth = async (
  email: string,
  sub: string = ''
): Promise<Session> => {
  const auth = DEFAULT_SESSION;

  auth.email = email;
  auth.roles = getRoles(email);
  auth.sub = sub;

  if (hasThreadsAccess(auth.roles)) {
    if (auth.sub === '') {
      const userAttributes = (await fetchUserAttributes()) as { sub: string };
      auth.sub = userAttributes.sub;
    }

    auth.promptUsage = await fetchPromptUsage(auth.sub);

    const { threads, hiddenThreadsCount } = await fetchThreads(auth.sub);
    auth.pendingPolicies = await fetchPendingPolicies(auth.sub);
    auth.threads = threads;
    auth.hiddenThreadsCount = hiddenThreadsCount;
  }

  return auth;
};
