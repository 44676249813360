import { FC, ReactNode } from 'react';
import styles from './checkbox-input.module.scss';

interface CheckboxInputProps {
  id: string;
  required?: boolean;
  children: ReactNode;
}

const CheckboxInput: FC<CheckboxInputProps> = ({
  required = false,
  id,
  children,
}) => {
  return (
    <div className={styles['checkbox-input']} data-required={required}>
      <input
        type="checkbox"
        value="Yes"
        name={id}
        id={id}
        required={required}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
};

export default CheckboxInput;
