import { AWS_USERS_API } from '@/config/aws';
import { PendingPolicy } from '@/models/user';
import { extractPayload, getSubHeader } from '@/utils/api';
import { get } from 'aws-amplify/api';

const fetchPendingPolicies = async (
  userSub: string
): Promise<PendingPolicy[]> => {
  let res: PendingPolicy[] = [];
  try {
    const { response } = get({
      apiName: AWS_USERS_API,
      path: '/base/users/policy-acceptance-status',
      options: {
        headers: getSubHeader(userSub),
      },
    });
    const { data } = await extractPayload<{
      data: {
        [key: string]: {
          policy_id: string;
          content: string;
          is_accepted: boolean;
        };
      };
    }>(response);

    const getPolicyMetadata = (
      key: string
    ): { title: string; link: string } => {
      switch (key) {
        case 'PRIVACY':
          return { title: 'Privacy Policy', link: '/privacy' };
        case 'TERMS':
          return { title: 'Terms of Use', link: '/terms-of-use' };
        default:
          return { title: '', link: '' };
      }
    };

    res = Object.keys(data)
      .map((key) => {
        const metadata = getPolicyMetadata(key);
        return {
          uuid: data[key].policy_id,
          id: key,
          title: metadata.title,
          link: metadata.link,
          content: data[key].content,
          needsApproval: !data[key].is_accepted,
        };
      })
      .filter((policy) => policy.needsApproval && policy.title !== '');
  } catch (error) {
    console.error(error);
  }

  return res;
};

export default fetchPendingPolicies;
