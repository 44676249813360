import { Thread } from './thread';
import { PendingPolicy, Role } from './user';

export enum Plan {
  Free = 'Free',
  Plus = 'Plus',
  DFS_INTERNAL = 'DFS',
  CustomForDeepCompareUsage = 'Custom',
  Enterprise = 'Enterprise',
}

export interface Session {
  sub: string;
  email: string;
  roles: Role[];
  threads: Thread[];
  pendingPolicies: PendingPolicy[];
  hiddenThreadsCount: number;
  promptUsage: PromptUsage;
}

export interface PromptUsage {
  plan: Plan;
  perDay: number;
  perMonth: number;
  usedToday: number;
  usedThisMonth: number;
  remainingToday: number;
  remainingThisMonth: number;
}

export const DEFAULT_SESSION: Session = {
  sub: '',
  email: '',
  roles: [],
  hiddenThreadsCount: 0,
  threads: [],
  pendingPolicies: [],
  promptUsage: {
    plan: Plan.Free,
    perDay: 0,
    perMonth: 0,
    usedToday: 0,
    usedThisMonth: 0,
    remainingToday: 0,
    remainingThisMonth: 0,
  },
};
