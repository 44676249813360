import React, { FC, ReactNode } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import { LexicalLinkNode, LexicalNode } from '@/models/lexical';
import styles from './lexical-renderer.module.scss';

interface LexicalRendererProps {
  content: LexicalNode[];
  isLoading: boolean;
}

const LexicalRenderer: FC<LexicalRendererProps> = ({ content, isLoading }) => {
  const renderNode = (node: LexicalNode): ReactNode => {
    // Handle text nodes
    if ('text' in node) {
      const text = node.text;

      // Apply text formatting if present
      if (node.bold) {
        return <strong>{text}</strong>;
      }
      if (node.italic) {
        return <em>{text}</em>;
      }
      if (node.underline) {
        return <u>{text}</u>;
      }
      if (node.code) {
        return <code>{text}</code>;
      }

      return text;
    }

    // Handle nodes with children
    const children = node.children?.map((child, index) => (
      <React.Fragment key={index}>{renderNode(child)}</React.Fragment>
    ));

    // Handle different node types
    switch (node.type) {
      case 'h1':
        return <h1>{children}</h1>;
      case 'h2':
        return <h2>{children}</h2>;
      case 'h3':
        return <h3>{children}</h3>;
      case 'h4':
        return <h4>{children}</h4>;
      case 'h5':
        return <h5>{children}</h5>;
      case 'h6':
        return <h6>{children}</h6>;
      case 'quote':
        return <blockquote>{children}</blockquote>;
      case 'list':
        if ('listType' in node && node.listType === 'bullet') {
          return <ul>{children}</ul>;
        }
        return <ol>{children}</ol>;
      case 'listitem':
        return <li>{children}</li>;
      case 'link':
        return (
          <a
            href={(node as LexicalLinkNode).url}
            target={(node as LexicalLinkNode).newTab ? '_blank' : undefined}
            rel={
              (node as LexicalLinkNode).newTab
                ? 'noopener noreferrer'
                : undefined
            }
          >
            {children}
          </a>
        );
      default:
        return <p>{children}</p>;
    }
  };

  return (
    <div className={styles['lexical-renderer']}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        content.map((node, index) => (
          <React.Fragment key={index}>{renderNode(node)}</React.Fragment>
        ))
      )}
    </div>
  );
};
export default LexicalRenderer;
