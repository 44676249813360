import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Error from '@/pages/Error';
import Account from '@/pages/Account';
import SignIn from '@/pages/SignIn';
import SignUp from '@/pages/SignUp';
import { SessionProvider } from '@/context/SessionProvider';
import NotFound from '@/pages/NotFound';
import Search from '@/pages/Search';
import Thread from '@/pages/Thread';
import RouteGuard from '../RouteGuard';
import { Role } from '@/models/user';
import RootLayout from '@/layouts/RootLayout';
import AppLayout from '@/layouts/AppLayout';
import ResetPassword from '@/pages/ResetPassword';
import AppHome from '@/pages/AppHome';
import ExploreData from '@/pages/ExploreData';
import UsersManagement from '@/pages/UsersManagement';
import Privacy from '@/pages/Privacy';
import Status from '@/pages/Status';
import ForgotPassword from '@/pages/ForgotPassword';
import ConfirmUser from '@/pages/ConfirmUser';
import TermsOfUse from '@/pages/TermsOfUse';
import PolicyReview from '@/pages/PolicyReview';
import Pricing from '@/pages/Pricing';
const BrowserRoutes = () => {
  return (
    <BrowserRouter>
      <SessionProvider>
        <Routes>
          <Route element={<RootLayout />} errorElement={<Error />}>
            <Route path="sign-up" element={<SignUp />} />
            <Route path="sign-in" element={<SignIn />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="confirmUser" element={<ConfirmUser />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="not-found" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/policy-review" element={<PolicyReview />} />
            <Route path="/status" element={<Status />} />
            <Route
              element={<RouteGuard allowedRoles={[Role.ADMIN, Role.USER]} />}
            >
              <Route element={<AppLayout />}>
                <Route index element={<AppHome />} />

                <Route path="thread" element={<Thread />}>
                  <Route path=":id" element={<Thread />} />
                </Route>
                <Route path="search" element={<Search />} />
                <Route path="explore-data" element={<ExploreData />} />
                <Route path="account" element={<Account />}>
                  <Route path="edit" />
                </Route>
                <Route element={<RouteGuard allowedRoles={[Role.ADMIN]} />}>
                  <Route
                    path="users-management"
                    element={<UsersManagement />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </SessionProvider>
    </BrowserRouter>
  );
};
export default BrowserRoutes;
