import { NavLink } from 'react-router-dom';
import useSession from '@/hooks/useSession';
import styles from './topbar.module.scss';
import { classnames } from '@/utils/functions';
import { FC } from 'react';

interface TopbarProps {
  float?: boolean;
}

const Topbar: FC<TopbarProps> = ({ float = false }) => {
  const { session } = useSession();

  const links: { label: string; link: string }[] = [
    { label: session.email ? 'App' : 'Sign In', link: '/' },
    { label: 'Pricing', link: '/pricing' },
    { label: 'Privacy', link: '/privacy' },
    { label: 'Terms of Use', link: '/terms-of-use' },
  ];

  return (
    <nav className={classnames(styles['topbar'], { [styles['float']]: float })}>
      {links.map(({ label, link }) => (
        <NavLink key={link} className={styles['link']} to={link}>
          {label}
        </NavLink>
      ))}
    </nav>
  );
};

export default Topbar;
