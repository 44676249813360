import useLoadingText from '../../hooks/useLoadingText';
import useThread from '../../hooks/useThread';
import Message from '../Message';
import { parseBlockID } from '../../utils/parse';
import styles from './block.module.scss';
import useBlock from '../../hooks/useBlock';
import { classnames } from '@/utils/functions';
import useSession from '@/hooks/useSession';
import FeedbackCTA from '@/components/FeedbackCTA';
import PricingTable from '@/components/PricingTable';

const Block = () => {
  const { status, handleRegenerate } = useThread();
  const { session } = useSession();
  const { remainingThisMonth, remainingToday } = session.promptUsage;
  const { block, isLast } = useBlock();
  const { id, prompt, response, queryDate, disliked } = block;
  const loadingText = useLoadingText(status);
  const isLoading = !response && status === 'FETCHING' && loadingText;

  const minutesUntilNextDay = () => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setUTCHours(0, 0, 0, 0);
    const timeDifference = tomorrow.getTime() - now.getTime();
    const minutes = Math.floor(timeDifference / (1000 * 60));
    return minutes;
  };

  return (
    <div
      data-testid="thread-block"
      className={classnames(styles['block'], {
        [styles['disliked']]: disliked,
      })}
      id={parseBlockID(id)}
      data-loading={!!(isLast && (status == 'TYPING' || isLoading))}
    >
      <span data-testid="block-date" className={styles['block-date']}>
        {queryDate.toLocaleString()}
      </span>
      <Message author="USER" content={prompt} />
      {isLast && status === 'ERROR' ? (
        <div data-testid={`block-error`} className={styles['error-banner']}>
          {remainingThisMonth <= 0 || remainingToday <= 0 ? (
            <>
              <h3>
                Thank you for participating in the Beta testing of Gannet! 🚀
              </h3>
              <p>
                You ran out of prompts for{' '}
                {remainingToday <= 0
                  ? `today, you have until tomorrow GMT to get more prompts (~${minutesUntilNextDay()} minutes).`
                  : 'the current month. You have to wait until next month to get more prompts.'}
              </p>

              <p>
                It would really help us if you could spare 5 minutes of your
                time to fill out a short survey.
              </p>
              <p>
                <FeedbackCTA
                  useLink={true}
                  text="Click here to fill out our feedback survey"
                />
              </p>
              <PricingTable />
            </>
          ) : (
            <>
              <p>Sorry, I am unable to process your request at the moment.</p>
              <button onClick={() => handleRegenerate(block)}>Try again</button>
            </>
          )}
        </div>
      ) : !isLoading ? (
        <Message
          content={response as string}
          author="ASSISTANT"
          lastMessage={isLast}
        />
      ) : (
        <Message content={loadingText} author="ASSISTANT" lastMessage={true} />
      )}
    </div>
  );
};

export default Block;
