import PricingTable from '@/components/PricingTable';
import styles from './pricing.module.scss';
import SEOHelmet from '@/components/SEOHelmet';
import Topbar from '@/components/Topbar';
const Pricing = () => {
  return (
    <article className={styles['pricing']}>
      <SEOHelmet
        title="Pricing"
        description="Discover our flexible pricing plans designed to meet your humanitarian AI needs."
      />
      <Topbar />
      <div className={styles['pricing-content']}>
        <h1>Pricing</h1>
        <p>
          Discover our flexible pricing plans designed to meet your humanitarian
          AI needs. Whether you're just starting to explore AI applications in
          humanitarian work, scaling up your research, or looking for
          enterprise-wide solutions, we have options tailored to your goals.
        </p>
        <p>
          Learn more about our pricing plans and choose the one that suits your
          needs, budget, and usage requirements.
        </p>
      </div>
      <PricingTable />
    </article>
  );
};

export default Pricing;
