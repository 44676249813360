import SEOHelmet from '@/components/SEOHelmet';
import LexicalRenderer from '@/components/LexicalRenderer';
import DocumentLayout from '@/layouts/DocumentLayout';
import useQueryCMS from '@/hooks/useQueryCMS';

const TermsOfUse = () => {
  const { data, isFetching } = useQueryCMS('TERMS');

  return (
    <DocumentLayout
      title="Terms of Use for GANNET - Humanitarian AI Assistant"
      lastUpdated={data.updated_at}
    >
      <SEOHelmet
        title="Terms of Use"
        description="Terms of Use for GANNET"
        index={true}
      />

      <LexicalRenderer content={data.content} isLoading={isFetching} />
    </DocumentLayout>
  );
};

export default TermsOfUse;
