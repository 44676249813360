import styles from './home-layout.module.scss';
import { FC, ReactNode } from 'react';
import { classnames } from '@/utils/functions';
import Topbar from '@/components/Topbar';

interface HomeLayoutProps {
  showNav?: boolean;
  children: ReactNode;
}

const HomeLayout: FC<HomeLayoutProps> = ({ showNav = true, children }) => {
  return (
    <section className={styles['home-layout']}>
      <div className={styles['img-wrapper']}>
        <img src="/gannet-bg.jpeg" alt="Gannet.ai background" />
      </div>

      <div
        className={classnames(styles['content'], {
          [styles['show-nav']]: showNav,
        })}
      >
        {showNav && <Topbar float={true} />}

        {children}
      </div>
    </section>
  );
};

export default HomeLayout;
