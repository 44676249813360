import useOutsideClickHandler from '@/hooks/useClickOutside';
import styles from './modal.module.scss';
import XmarkIcon from '@/assets/icons/xmark.svg?react';
import { FC, ReactNode } from 'react';

interface ModalProps {
  children: ReactNode;
  title?: string;
  isOpen: boolean;
  size?: 'medium' | 'large';
  onClose?: () => void;
}

const Modal: FC<ModalProps> = ({
  children,
  title,
  isOpen,
  onClose,
  size = 'medium',
}) => {
  const isCloseable = !!onClose;
  const closePopover = () => {
    if (isCloseable) {
      onClose();
    }
  };
  const modalRef = useOutsideClickHandler<HTMLDivElement>(closePopover);

  if (!isOpen) return null;

  return (
    <>
      <div ref={modalRef} className={styles['modal']} data-size={size}>
        <div className={styles['modal-header']}>
          {title && <h2>{title}</h2>}
          {isCloseable && (
            <XmarkIcon
              className={styles['close-xmark']}
              onClick={closePopover}
            />
          )}
        </div>

        <div className={styles['modal-content']}>{children}</div>
      </div>

      <div className={styles['modal-backdrop']}></div>
    </>
  );
};

export default Modal;
