import { FC } from 'react';
import Button from '../Button';
import styles from './pricing-table.module.scss';
import useSession from '@/hooks/useSession';
import CircleInfoIcon from '@/assets/icons/circle-info.svg?react';
import CheckIcon from '@/assets/icons/check.svg?react';
import EmailLink from '../EmailLink';
import { Plan } from '@/models/session';
import { useNavigate } from 'react-router-dom';

enum PlanFeatureType {
  INCLUDED = 'included',
  RESTRICTED = 'restricted',
}

interface PlanDetails {
  id: Plan;
  name: string;
  description: string;
  price?: number;
  features: {
    type: PlanFeatureType;
    description: string;
  }[];
  link?: string;
}

const PLANS: PlanDetails[] = [
  {
    id: Plan.Free,
    name: 'Starter',
    description:
      'Basic access to GANNET with essential features for those who want to explore the possibilities of  AI in the humanitarian ecosystem.',
    price: 0,
    features: [
      { type: PlanFeatureType.INCLUDED, description: '10 Prompts per day.' },
      { type: PlanFeatureType.INCLUDED, description: '70 Prompts per month.' },
      {
        type: PlanFeatureType.RESTRICTED,
        description: 'Only keeps the ten most recent Threads.',
      },
      {
        type: PlanFeatureType.RESTRICTED,
        description: 'No search capabilities across Threads.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Smart Thread navigation with Table of Contents.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Bibliography summary per Thread.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Access to Data Explorer.',
      },
    ],
  },
  {
    id: Plan.Plus,
    name: 'Plus',
    description:
      'Access to advanced data and features. For those who need more prompts and data in their search for trusted humanitarian information.',
    price: 30,
    features: [
      { type: PlanFeatureType.INCLUDED, description: '25 Prompts per day.' },
      {
        type: PlanFeatureType.INCLUDED,
        description: '250 Prompts per month.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Keeps archive of Threads for one year.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Search across Threads.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Everything in Starter plan.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Early access to new features.',
      },
    ],
    link: import.meta.env.VITE_STRIPE_PLUS_URL,
  },

  {
    id: Plan.Enterprise,
    name: 'Enterprise',
    description:
      'Customized and tailored access to GANNET for your organization. For those that need custom solutions to fit their needs.',
    features: [
      {
        type: PlanFeatureType.INCLUDED,
        description: 'All Starter and Plus features.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Volume licensing and custom pricing.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Enhanced support options.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Organization-wide account management.',
      },
    ],
  },
];

interface PricingTableProps {
  heading?: boolean;
}

const PricingTable: FC<PricingTableProps> = ({ heading = false }) => {
  const {
    session: { email, promptUsage },
    pushUserEvent,
  } = useSession();

  const isUser = email !== '';
  const navigate = useNavigate();

  const currentPlan = promptUsage.plan;

  return (
    <div className={styles['pricing-table-wrapper']}>
      {heading && <h2>Choose the right plan for You</h2>}

      <div className={styles['pricing-table']}>
        {PLANS.map(({ id, name, price, description, features, link }) => {
          const isCurrentPlan = isUser && currentPlan === id;

          return (
            <div
              key={id}
              className={styles['pricing-plan']}
              data-current={isCurrentPlan}
            >
              <div>
                <h3>{name}</h3>
                {typeof price !== 'undefined' && (
                  <h4>
                    ${price} <span>/ month</span>
                  </h4>
                )}
              </div>
              <p>{description}</p>

              <ul>
                {features.map(({ type, description }, id) => (
                  <li key={id} data-feature-type={type}>
                    {type === PlanFeatureType.INCLUDED && (
                      <CheckIcon className={styles['icon']} />
                    )}
                    {type === PlanFeatureType.RESTRICTED && (
                      <CircleInfoIcon className={styles['icon']} />
                    )}

                    {description}
                  </li>
                ))}
              </ul>

              {id !== 'Free' && isUser && !isCurrentPlan && (
                <Button
                  onClick={() => {
                    pushUserEvent(
                      `${
                        link ? 'click_upgrade' : 'click_request_upgrade'
                      }_${id}`
                    );
                    if (link) {
                      window.open(
                        `${
                          import.meta.env.VITE_STRIPE_PLUS_URL
                        }?prefilled_email=${email}`,
                        '_blank'
                      );
                    } else {
                      window.location.href = `mailto:info@gannet.ai?subject=Request Upgrade to ${name}`; // Simulate mailto click
                    }
                  }}
                >
                  {link ? `Get ${name}` : 'Request Upgrade'}
                </Button>
              )}
            </div>
          );
        })}
      </div>

      {!isUser && (
        <Button
          className={styles['get-started-btn']}
          onClick={() => {
            pushUserEvent('click_get_started');
            navigate('/sign-up');
          }}
        >
          Get Started for FREE
        </Button>
      )}

      <p className={styles['pricing-contact']}>
        If you have questions don't hesitate to contact us at <EmailLink />.
      </p>
    </div>
  );
};

export default PricingTable;
