import useSession from '@/hooks/useSession';
import styles from './policy-review.module.scss';
import Button from '@/components/Button';
import CheckboxInput from '@/components/CheckboxInput';
import EmailLink from '@/components/EmailLink';
import Modal from '@/components/Modal/modal';
import { AWS_USERS_API } from '@/config/aws';
import { getSubHeader, extractPayload } from '@/utils/api';
import { post } from 'aws-amplify/api';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SEOHelmet from '@/components/SEOHelmet';

const PolicyReview = () => {
  const { session, setSession, pushUserEvent } = useSession();
  const [acceptingPolicies, setAcceptingPolicies] = useState(false);
  const { pendingPolicies, sub } = session;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== '/policy-review' && pendingPolicies.length === 0) {
      navigate('/', { replace: true });
    } else {
      pushUserEvent('notice_policies_updates');
    }
  }, [navigate, pathname, pendingPolicies.length, pushUserEvent]);

  return (
    <main className={styles['policy-review']}>
      <SEOHelmet title="Policy Review" index={false} />
      <Modal title="Policy Update Alert!" isOpen={true}>
        <div className={styles['policies-modal-content']}>
          <p>
            We've refreshed our policies to ensure your data stays safe. We're
            all about secure-by-default experiences that put you in charge of
            your information. Our updated policies spell out exactly how we use
            your data, so there's no guessing—just trust. Understanding these
            updates means we can keep delivering that ultimate online experience
            you deserve.
          </p>
          <p>
            To ensure you're up to date with our recent changes, please read and
            accept the following policies to continue using GANNET:
          </p>
          <form
            onSubmit={(event) => {
              setAcceptingPolicies(true);
              try {
                event.preventDefault();

                interface acceptResponse {
                  success: boolean;
                }
                const promises: Promise<acceptResponse>[] = [];

                for (const policy of pendingPolicies) {
                  const checkbox = document.getElementById(
                    policy.id
                  ) as HTMLInputElement;
                  if (!checkbox.checked) {
                    throw new Error('Please accept all policies');
                  }

                  const { response } = post({
                    apiName: AWS_USERS_API,
                    path: `/base/policies/accept`,
                    options: {
                      headers: getSubHeader(sub),
                      body: {
                        policy_id: policy.uuid,
                      },
                    },
                  });

                  const promise = extractPayload<acceptResponse>(response);

                  promises.push(promise);
                }

                Promise.all(promises).then((results: acceptResponse[]) => {
                  if (results.some((result) => !result.success)) {
                    pushUserEvent('error_policies_updates');
                    throw new Error('Failed to accept policies');
                  }

                  pushUserEvent('accept_policies_updates');
                  setSession((prev) => ({ ...prev, pendingPolicies: [] }));
                  navigate('/', { replace: true });
                });
              } catch (err) {
                setAcceptingPolicies(false);
                console.error(err);
              }
            }}
          >
            {pendingPolicies.map(({ title, link, id }) => (
              <CheckboxInput key={id} id={id} required={true}>
                I have read and agree to the updated{' '}
                <Link to={link} target="_blank">
                  {title}
                </Link>
                .
              </CheckboxInput>
            ))}

            <Button
              className={styles['continue-btn']}
              type="submit"
              loading={acceptingPolicies}
            >
              Continue
            </Button>
          </form>
          <p className={styles['contact']}>
            If you have any questions, please feel free to contact us at{' '}
            <EmailLink />
          </p>
        </div>
      </Modal>
    </main>
  );
};

export default PolicyReview;
