import SEOHelmet from '@/components/SEOHelmet';
import LexicalRenderer from '@/components/LexicalRenderer';
import DocumentLayout from '@/layouts/DocumentLayout';
import useQueryCMS from '@/hooks/useQueryCMS';

const Privacy = () => {
  const { data, isFetching } = useQueryCMS('PRIVACY');

  return (
    <DocumentLayout
      title="Privacy Policy for GANNET - Humanitarian AI Assistant"
      lastUpdated={data.updated_at}
    >
      <SEOHelmet
        title="Privacy Policy"
        description="Privacy policy for GANNET"
        index={true}
      />

      <LexicalRenderer content={data.content} isLoading={isFetching} />
    </DocumentLayout>
  );
};

export default Privacy;
