import styles from './document-layout.module.scss';
import { FC, ReactNode } from 'react';
import Topbar from '@/components/Topbar';

interface DocumentLayoutProps {
  title: string;
  lastUpdated: string;
  children: ReactNode;
}

const DocumentLayout: FC<DocumentLayoutProps> = ({
  title,
  lastUpdated,
  children,
}) => {
  return (
    <div className={styles['document-layout']}>
      <Topbar />
      <div>
        <h1>{title}</h1>
        {lastUpdated && (
          <p className={styles['last-updated']}>
            Last updated on <strong>{lastUpdated.split('T')[0]}</strong>
          </p>
        )}
        {children}
      </div>
    </div>
  );
};

export default DocumentLayout;
